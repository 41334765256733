<template>
  <section :class="$style.article">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" @input="handleNameInput"></el-input>
      </el-form-item>
      <el-form-item label="Название ссылки" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>

      <el-form-item label="SEO текста">
        <SeoTextsBlock
          :seoTexts="form.seoTexts"
          @getSeo="getSeoTexts"
          @deleteSeo="deleteSeoText"
        />
      </el-form-item>
      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock
          :seoFAQs="form.seoFaqs"
          @getSeo="getSeoFaqs"
          @deleteSeo="deleteSeoFaq"
        />
      </el-form-item>
      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
      <el-button type="primary" @click="SaveAndComeBack('form')">
        Сохранить и вернуться
      </el-button>
    </el-form>
  </section>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'

import { transliterate } from '@/helpers/slug'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

export default {
  components: {
    SeoBlock,
    SeoTextsBlock,
    SeoFaqsBlock,
  },
  mixins: [notifications],
  ADDWINE_ADMIN_ROUTES,
  data() {
    return {
      categoriesOptions: [],
      form: {
        name: '',
        slug: '',
        seoTexts: [],
        seoFaqs: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      defaultSeo: {
        title: '',
        description: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],

        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },

  async created() {
    await this.getArticleCategory()
  },

  methods: {
    async deleteSeoText(seoId) {
      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.ContentServiceCore.ArticleCategoriesActions.deleteSeoText(
          this.$route.params.id,
          seoId,
        )

      loading.close()

      if (error) return

      await this.getArticleCategory()

      this.showNotification('Seo текст успешно удален', 'success')
    },
    async deleteSeoFaq(seoId) {
      const loading = this.$loading({
        lock: true,
      })

      const { error } =
        await delivery.ContentServiceCore.ArticleCategoriesActions.deleteSeoFaq(
          this.$route.params.id,
          seoId,
        )

      loading.close()

      if (error) return

      await this.getArticleCategory()

      this.showNotification('Seo Faq успешно удален', 'success')
    },
    async getArticleCategory() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.ArticleCategoriesActions.getOne(
          this.$route.params.id,
        )

      loading.close()

      if (error) {
        return
      }

      value.seoTexts = value.seoTexts?.map((seoText) => seoText.id) ?? []
      value.seoFaqs = value.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []
      value.seo = value.seo ? value.seo : this.defaultSeo

      this.form = { ...value }
    },

    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          let data = {
            ...this.form,
            seoTexts: this.form.seoTexts.map((id) => ({ id })),
            seoFaqs: this.form.seoFaqs.map((id) => ({ id })),
          }

          const result =
            await delivery.ContentServiceCore.ArticleCategoriesActions.update(
              this.$route.params.id,
              data,
            )

          loading.close()

          if (result.error) return

          this.showNotification('Категория успешно обновлена', 'success')
        }
      })
    },
    async SaveAndComeBack(form){
      await this.submitForm(form)
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" module>
.article {
  padding: 1rem;
  .form {
    & > div > label {
      text-align: left;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }

    .date {
      width: 100%;
    }
    .categories {
      width: 100%;
    }
  }
}
.seo {
  margin-top: 2rem;
}
</style>
